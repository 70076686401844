import React, { useEffect } from 'react';
import { useAppDispatch } from '../../store/store';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { sessionInfoThunk, setSessionId } from '../../store/qr';
import { setFailure } from '../../store/system';
import { setLang } from '../../store/lang';
import * as en from '../lang/en.json';
import * as pl from '../lang/pl.json';
import * as ua from '../lang/ua.json';

export default function SessionProtect() {
  const dispatch = useAppDispatch();
  const { sessionId } = useParams();

  useEffect(() => {
    if (sessionId) {
      init();
    }

    function _setLang(lang: string) {
      switch (lang) {
        case 'En':
          dispatch(setLang(en));
          break;
        case 'Pl':
          dispatch(setLang(pl));
          break;
        default:
          dispatch(setLang(ua));
          break;
      }
    }

    async function init() {
      dispatch(setSessionId(sessionId));
      await dispatch(sessionInfoThunk(() => dispatch(setFailure(true)))).then(
        (res) => _setLang(res.payload.language),
      );
    }
  }, [dispatch, sessionId]);

  return sessionId ? <Outlet /> : <Navigate to='/error' />;
}
