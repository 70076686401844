import { useNavigate } from 'react-router-dom';
import { setSaveFailure, setLoading, rateThunk } from '../store/system';
import { useAppDispatch, useAppSelector } from '../store/store';
import { redirectOnPlatform } from '../api/api';
import Main from './components/main';
import { IconType, RateResponse, Status } from '../api/api_types';

function MainPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.system);

  const sendReview = ({ id, isPositive, rate }: { id?: string, isPositive?: boolean, rate?: number }) => {
    if (!id) {
      return;
    }

    dispatch(setLoading(true));
    dispatch(rateThunk({ id, isPositive, rate }))
      .then((res) => {
        if (res.meta.requestStatus === 'fulfilled') {
          const data =  res.payload as {
            data: RateResponse;
            isPositive: boolean;
          };
          if (isPositive || data.data.status === Status.PositiveRate) {
            if (data.data?.attributes) {
              navigate('/attributes');
            } else if (
              data.data.mainRankPlatformType &&
              data.data.redirectOnRate
            ) {
              redirect(data.data);
            } else if (data.data.mainRankPlatformType) {
              navigate('/platform');
            } else {
              navigate('/social');
            }
          } else {
            navigate('/feedback');
          }
          dispatch(setSaveFailure(false));
        }
      })
      .catch((error) => {
        dispatch(setSaveFailure(true));
        dispatch(setLoading(false));
      });
    dispatch(setLoading(false));
  };

  function redirect(data: RateResponse) {
    if (data.redirectOnRate) {
      redirectOnPlatform(state.info!.id, getPlatformId(data));
    } else {
      navigate('/platform');
    }
  }

  function getPlatformId(data: RateResponse) {
    const platforms = data.rankPlatforms;
    const redirectPlatform = platforms.find(
      (p) => p.rankPlatformType === data.mainRankPlatformType,
    );
    return redirectPlatform?.rankPlatformId ?? '';
  }

  return (
    <Main
      mainQuestion={state.info?.mainQuestion ?? ''}
      onRateClick={sendReview}
      id={state.info?.id}
      iconType={state.info?.rateIconType ?? IconType.Emoji}
    />
  );
}

export default MainPage;
