export interface OrderInfo {
  id: string;
  status: Status;
  language: string;
  mainQuestion: string;
  negativeFilterEnabled: boolean;
  hasFeedback: boolean;
  redirectOnRate: boolean;
  mainRankPlatformType: RankPlatformName;
  rankPlatforms: PlatformInfo[];
  attributes: string[] | null;
  rateIconType: IconType;
  negativeTitle: string;
  negativeQuestion: string

}

export enum IconType {
  Emoji = 'Emoji',
  LikeDislike = 'LikeDislike',
  FiveStarts = 'FiveStars',
}

export enum RankPlatformName {
  Google = 'GoogleMaps',
  Trustpilot = 'Trustpilot',
  Yelp = 'Yelp',
  Tripadvisor = 'Tripadvisor',
  Facebook = 'Facebook',
  FacebookPost = 'FacebookPost',
  InstagramPost = 'InstagramPost',
  Hotline = 'Hotline',
  Custom = 'Custom',
}

export enum Status {
  Pending = 'Pending',
  NegativeRate = 'NegativeRate',
  PositiveRate = 'PositiveRate',
  Opened = 'Opened',
  Sent = 'Sent',
}

export interface QrRateStepResponse {
  id: string;
  language: string;
  mainQuestion?: string;
  negativeFilterEnabled: boolean;
}

export interface QrRateRequest {
  qrCodeSessionId: string;
  isPositive?: boolean;
  rate?: number;
}

export interface QrNegativeStepResponse {
  id: string;
  language: string;
}

export interface QrNegativeFeedbackRequest {
  qrCodeSessionId: string;
  phone: string;
  feedback: string;
}

export interface QrPositiveStepResponse {
  id: string;
  language: string;
  redirectOnRate: boolean;
  mainRankPlatformType: RankPlatformName;
  rankPlatforms: PlatformInfo[];
  attributes: string[];
}

export interface PlatformInfo {
  rankPlatformId: string;
  name: string;
  rankPlatformType: RankPlatformName;
  color: string;
}

export interface RateResponse {
  status: Status;
  mainRankPlatformType: RankPlatformName;
  redirectOnRate: boolean;
  rankPlatforms: PlatformInfo[];
  attributes: string[] | null;
}

export interface SelectAttributesRequest {
  reviewInviteId: string;
  attributes: string[];
}

export interface SessionInfo {
  qrCodeSessionId: string;
  qrCodeId: string;
  language: string;
  isPositiveRated: boolean;
  hasFeedback: boolean;
  negativeFilterEnabled: boolean;
  mainQuestion: string;
  mainRankPlatformType: RankPlatformName;
  redirectOnRate: boolean;
  rankPlatforms: PlatformInfo[];
  attributes: string[] | null;
  rateIconType: IconType;
  negativeTitle: string;
  negativeQuestion: string;
}

export interface AuditInfo {
  name: string;
  address: string;
  descriptionHtml: string;
  createdAt: string;
  searchPosition: number;
  shareTopPositionRequests: number;
  competitors: number;
  winnedCompetitors: number;
  keywords: KeywordsInfo[];
  currentPlace: PlaceInfo;
  competitorPlaces: PlaceInfo[];
  points: Point[];
  defaultMapZoom: number;
  initialCenter: LocationCoordinate;
  reviewPoints: ReviewPoint[];
  reviewChartPoints: ChartPoint[];
  pageOptimizationItems: AdditionalInfo[];
  services: Service[];
}

export interface ChartPoint {
  currentPlace: boolean;
  reviewRank: number;
  reviews: number;
  hide: boolean;
}

export interface KeywordsInfo {
  keyword: string;
  requests: number;
  levels: LevelInfo[];
}

export interface LevelInfo {
  distance: number;
  position: number;
  hide: boolean;
}

export interface PlaceInfo {
  name: string;
  address: string;
  position: number;
  reveiwRank: number;
  reveiwCount: number;
  photoReference: string;
}

export interface Point {
  lat: number;
  lng: number;
  position: number;
  hide: boolean;
}

export interface ReviewPoint {
  lat: number;
  lng: number;
  rank: number;
  reviewCount: number;
  hide: boolean;
}

export interface AdditionalInfo {
  text: string;
  checked: boolean;
  hintHtml: string;
}

export interface Service {
  name: string;
  textHtml: string;
  price: number;
}

export interface LocationCoordinate {
  lat: number;
  lng: number;
}

export interface ApiFailure {
  code: number;
  message: string;
}
