import { motion } from 'framer-motion';
import DefaultBtn from '../modules/default_btn';
import { useAppSelector } from '../../store/store';
import { Typography } from '@mui/material';
import { defaultCountries, parseCountry, PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

interface FeedbackPageProps {
  onChange: (event: any) => void;
  onPhoneChange?: (val: any, mask: any) => void;
  onClick: () => void;
  loading: boolean;
  text: string;
  phone?: string;
  title?: string;
  question?: string;
  disabled: boolean;
  phoneError?: boolean;
}
export default function FeedbackPage(props: FeedbackPageProps) {
  const { onChange, onPhoneChange, onClick, loading, text, disabled, title, question, phoneError, phone } = props;
  const lang = useAppSelector((state) => state.lang.lang);

  const countries = defaultCountries.filter((country) => {
    const { iso2 } = parseCountry(country);
    return ['ua', 'pl'].includes(iso2);
  });

  return (
    <motion.div
      className='feedback anim'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <h2 className='feedback__title'>{title ?? lang.feedbackTitle}</h2>
      <p className='feedback__text'>{question ?? lang.feedbackContent}</p>
      {onPhoneChange && <PhoneInput defaultCountry="ua"
        onChange={onPhoneChange}
        countries={countries} />
      }
      {phoneError && <Typography className='phone__error'>{lang.phoneError}</Typography>}
      <textarea
        onChange={onChange}
        className='feedback__input'
        value={text}
        placeholder={lang.plaseholderSory}
        autoFocus={true}
      />
      <DefaultBtn
        disabled={disabled}
        loading={loading}
        onClick={onClick}
        text={lang.submit}
      />
    </motion.div>
  );
}
