import { useAppDispatch, useAppSelector } from '../../store/store';
import Loading from './loading';
import { rateThunk, sessionInfoThunk } from '../../store/qr';
import { setFailure } from '../../store/system';
import Main from '../components/main';
import FeedbackPage from '../components/feedback';
import { useState } from 'react';
import { IconType, QrNegativeFeedbackRequest, SessionInfo } from '../../api/api_types';
import { qrNegativeFeedback } from '../../api/api';
import Success from '../success';
import MainPlatformView from './main_platform_view';

export default function QRFlowView() {
  const { sessionInfo, loading } = useAppSelector((state) => ({
    sessionInfo: state.qr.sessionInfo,
    loading: state.qr.loading,
  }));

  if (loading) {
    return <Loading />;
  } else if (sessionInfo?.negativeFilterEnabled) {
    return (
      <WithNegativeFilterPages sessionInfo={sessionInfo} loading={loading} />
    );
  } else {
    return <MainPlatformView sessionInfo={sessionInfo} />;
  }
}

interface NegativeFilterPagesProps {
  sessionInfo: SessionInfo;
  loading: boolean;
}

function WithNegativeFilterPages(props: NegativeFilterPagesProps) {
  const dispatch = useAppDispatch();

  const { sessionInfo, loading } = props;
  const [feedback, setFeedback] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState(false);

  const onRate = async ({ id, isPositive, rate }: { id?: string, isPositive?: boolean, rate?: number }) => {
    await dispatch(
      rateThunk({
        isPositiveRate: isPositive,
        rate: rate,
        onReject: () => dispatch(setFailure(true)),
      }),
    );
    dispatch(sessionInfoThunk(() => dispatch(setFailure(true))));
  };

  const onFeedbackSend = async () => {

    var _phone = phone.replace("+", "");
    if (_phone.length < 11) {
      setPhoneError(true);
      return;
    }

    setPhoneError(false);

    const body: QrNegativeFeedbackRequest = {
      qrCodeSessionId: sessionInfo.qrCodeSessionId,
      feedback: feedback,
      phone: _phone,
    };

    const result = await qrNegativeFeedback(body);
    if (result) {
      dispatch(sessionInfoThunk(() => dispatch(setFailure(true))));
    }
  };

  if (sessionInfo.isPositiveRated) {
    return <MainPlatformView sessionInfo={sessionInfo} />;
  } else if (sessionInfo.isPositiveRated === undefined) {
    return loading ? (
      <Loading />
    ) : (
      <Main
        mainQuestion={sessionInfo?.mainQuestion ?? ''}
        onRateClick={onRate}
        iconType={sessionInfo?.rateIconType ?? IconType.Emoji}
      />
    );
  } else {
    return sessionInfo.hasFeedback ? (
      <Success />
    ) : (
      <FeedbackPage
        title={sessionInfo.negativeTitle}
        question={sessionInfo.negativeQuestion}
        loading={loading}
        onClick={onFeedbackSend}
        disabled={feedback.length === 0}
        onChange={(event) => setFeedback(event.target.value)}
        onPhoneChange={(val) => { setPhone(val); console.log(val) }}
        text={feedback}
        phoneError={phoneError}
        phone={phone}
      />
    );
  }
}
